








import { MetaInfo } from 'vue-meta';
import { Component, Vue } from 'vue-property-decorator';

@Component({
   metaInfo(): MetaInfo {
      return {
         title: this.$t(this.$route.meta.title).toString(),
         meta: [
            {
               vmid: 'description',
               name: 'description',
               content: this.$t(this.$route.meta.description).toString()
            }
         ]
      };
   }
})
export default class Template extends Vue {
   get content(): string {
      return this.$route.meta.content;
   }
}
